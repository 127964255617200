import {
  type SwapRequestInfoFragment,
  type SwapRequestFragment,
  type SwapFeeType,
} from '@/shared/graphql/generated/graphql';
import { TokenAmount, chainflipAssetMap } from '@/shared/utils';

const getEgressStatus = (egress: NonNullable<SwapRequestFragment['egress']>, prefix?: string) => {
  if (egress.broadcast) {
    if (egress.broadcast.broadcastSuccessEventId) return `${prefix ?? ''}BROADCAST_SUCCEEDED`;
    if (egress.broadcast.broadcastAbortedEventId) return `${prefix ?? ''}BROADCAST_ABORTED`;
    return `${prefix ?? ''}BROADCAST_REQUESTED`;
  }
  return `${prefix ?? ''}EGRESS_SCHEDULED`;
};

export const getSwapStatus = (swapRequest: SwapRequestInfoFragment | SwapRequestFragment) => {
  if (swapRequest.egress) return getEgressStatus(swapRequest.egress);
  if (swapRequest.ignoredEgressId) return 'EGRESS_IGNORED';
  if (swapRequest.refundEgress) return getEgressStatus(swapRequest.refundEgress, 'REFUND_');
  if (swapRequest.ignoredRefundEgressId) return 'REFUND_EGRESS_IGNORED';
  return 'SWAP_SCHEDULED';
};

export const isSuccessStatus = (status: SwapStatus): status is 'BROADCAST_SUCCEEDED' =>
  status === 'BROADCAST_SUCCEEDED';

export const isRefundedStatus = (status: SwapStatus): status is 'REFUND_BROADCAST_SUCCEEDED' =>
  status === 'REFUND_BROADCAST_SUCCEEDED';

export const isFailedStatus = (
  status: SwapStatus,
): status is
  | 'BROADCAST_ABORTED'
  | 'EGRESS_IGNORED'
  | 'REFUND_BROADCAST_ABORTED'
  | 'REFUND_EGRESS_IGNORED' =>
  status === 'BROADCAST_ABORTED' ||
  status === 'EGRESS_IGNORED' ||
  status === 'REFUND_BROADCAST_ABORTED' ||
  status === 'REFUND_EGRESS_IGNORED';

export type SwapStatus = ReturnType<typeof getSwapStatus>;

type Fee = SwapRequestInfoFragment['fees']['nodes'][number];

export const getFee = (fees: Fee[], feeType: SwapFeeType) => {
  const fee = fees.find(({ type }) => type === feeType);

  return (
    fee && {
      asset: fee.asset,
      amount: new TokenAmount(fee.amount, chainflipAssetMap[fee.asset].decimals),
      valueUsd: Number(fee.valueUsd ?? 0),
    }
  );
};
