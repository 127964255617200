import React, { useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import {
  AgeWithTooltip,
  Button,
  CompactSwapRoute,
  CopyButton,
  Link,
  SearchInput,
  Tooltip,
} from '@/shared/components';
import { TableV2 } from '@/shared/components/molecules/TableV2';
import RouteTooltip from '@/shared/components/RouteTooltip';
import { type SwapRequestFragment } from '@/shared/graphql/generated/graphql';
import { useGqlQuery } from '@/shared/hooks/useGqlQuery';
import useRemotePagination from '@/shared/hooks/useRemotePagination';
import { ArrowIcon } from '@/shared/icons/large';
import { ClockIcon, TimeBoost } from '@/shared/icons/small';
import {
  abbreviate,
  chainflipAssetMap,
  getSwapCompletionTime,
  isInteger,
  isNullish,
} from '@/shared/utils';
import { assetConstants, buildExplorerLink } from '@/shared/utils/chainflip';
import { getAllSwapsQuery, getSwapByNativeIdQuery } from 'packages/block-explorer/queries/swaps';
import { getSwapStatus, isSuccessStatus } from '../../helpers/swap';
import { SwapStatusPill } from '../SwapStatusPill';

export const SwapRow = ({
  swapRequest,
  highlightAddress,
  setHighlightAddress,
}: {
  swapRequest: NonNullable<SwapRequestFragment>;
  highlightAddress?: string;
  setHighlightAddress?: (address: string) => void;
}) => {
  const router = useRouter();
  const sourceChain = assetConstants[swapRequest.sourceAsset].chain;
  const destinationChain = assetConstants[swapRequest.destinationAsset].chain;
  const status = getSwapStatus(swapRequest);
  const swapPath = `/swaps/${swapRequest.nativeId}`;
  const depositChannelCreationTimestamp = swapRequest.channel?.issuedEvent.block.timestamp;
  const egressTimestamp = swapRequest.egress?.event?.block.timestamp;
  const depositTimestamp = swapRequest.depositBlock?.stateChainTimestamp;
  const preDepositBlockTimestamp = swapRequest.preDepositBlock?.stateChainTimestamp;
  const destinationAddress = swapRequest.destinationAddress ?? '';
  const [swap] = swapRequest.swaps.nodes;

  return (
    <tr
      className="transition:ease-in text-xs duration-150 hover:cursor-pointer hover:bg-cf-gray-3"
      onClick={(e) => {
        if (e.ctrlKey || e.metaKey) {
          window.open(swapPath, '_blank');
        } else {
          router.push(swapPath);
        }
      }}
    >
      <td className="whitespace-nowrap font-aeonikMono">
        <div className="flex flex-col gap-y-3 lg:flex-row">
          <div className="flex flex-row items-center gap-x-2 pr-1 lg:w-2/3">
            <Link href={swapPath} underline noPropagate>
              #{swapRequest.nativeId}
            </Link>
            <span className="flex items-center gap-x-1 font-aeonikRegular">
              Swap {chainflipAssetMap[swapRequest.sourceAsset].symbol} for{' '}
              {chainflipAssetMap[swapRequest.destinationAsset].symbol}
              {swapRequest.ccmDepositReceivedEventId && (
                <div className="flex h-[22px] w-12 items-center justify-center rounded-full border border-cf-blue-2 border-opacity-20 bg-cf-blue-5 p-1 font-aeonikRegular text-cf-blue-2">
                  CCM
                </div>
              )}
            </span>
          </div>
          <div className="flex flex-row items-center space-x-2 lg:w-1/2">
            <ArrowIcon width={18} className="text-cf-green-1" />
            <span className="font-aeonikRegular text-cf-light-2">To</span>
            <Tooltip content={destinationAddress}>
              <Link
                noPropagate
                href={buildExplorerLink(destinationChain, 'address', destinationAddress)}
                underline
                onMouseOver={() => setHighlightAddress?.(destinationAddress)}
                onMouseOut={() => setHighlightAddress?.('')}
                className={classNames(
                  highlightAddress === destinationAddress
                    ? 'border-cf-green-1 bg-cf-green-4'
                    : 'border-transparent',
                  'rounded-md border border-dashed p-1',
                )}
              >
                {abbreviate(destinationAddress)}
              </Link>
            </Tooltip>
            <CopyButton textToCopy={destinationAddress} />
          </div>
        </div>
      </td>

      <td>
        <div className="inline-flex">
          <Tooltip
            disabled={isNullish(swapRequest.egress?.amount)}
            content={<RouteTooltip swapRequest={swapRequest} type="Order" />}
          >
            <CompactSwapRoute
              routeInfo={{
                destinationAsset: swapRequest.destinationAsset,
                sourceAsset: swapRequest.sourceAsset,
              }}
              swapRequests={[swapRequest]}
            />
          </Tooltip>
        </div>
      </td>

      <td className="min-w-[150px]">
        <SwapStatusPill status={status} />
      </td>

      <td className="min-w-[150px]">
        {isSuccessStatus(status) && egressTimestamp && depositTimestamp && (
          <div className="flex items-center gap-x-1 font-aeonikMono text-12">
            {swapRequest.effectiveBoostFeeBps != null && swapRequest.effectiveBoostFeeBps > 0 ? (
              <TimeBoost className="text-cf-pink-1" />
            ) : (
              <ClockIcon />
            )}
            {getSwapCompletionTime({
              sourceChain,
              depositChannelCreationTimestamp: depositChannelCreationTimestamp
                ? new Date(depositChannelCreationTimestamp)
                : undefined,
              depositTimestamp: new Date(depositTimestamp),
              preDepositBlockTimestamp: preDepositBlockTimestamp
                ? new Date(preDepositBlockTimestamp)
                : undefined,
              egressTimestamp: new Date(egressTimestamp),
              exact: false,
            })}
          </div>
        )}
      </td>

      <td
        className="whitespace-nowrap text-right font-aeonikMono text-cf-light-2"
        title={new Date(swap.swapScheduledEvent.block.timestamp).toLocaleString()}
      >
        <AgeWithTooltip timestamp={swap.swapScheduledEvent.block.timestamp} />
      </td>
    </tr>
  );
};

export default function LatestSwapsTable({ preview = false }: { preview?: boolean }) {
  const [query, setQuery] = useState('');
  const router = useRouter();
  const [highlightAddress, setHighlightAddress] = useState('');

  const {
    data: swapsByDestinationAddress,
    isLoading: swapsByDestinationAddressLoading,
    paginationControls: allSwapsPaginationControls,
  } = useRemotePagination(getAllSwapsQuery, {
    useQueryParam: !preview,
    variables: {
      destinationAddress: query.toLowerCase(),
    },
    enabled: !isInteger(query),
  });

  const { data: swapsByNativeId, isLoading: swapsByNativeIdLoading } = useGqlQuery(
    getSwapByNativeIdQuery,
    {
      variables: {
        nativeId: query,
      },
      enabled: isInteger(query),
    },
  );

  const swaps = useMemo(
    () =>
      swapsByDestinationAddress?.allSwapRequests?.edges ||
      (swapsByNativeId?.swap && [{ node: swapsByNativeId.swap }]) ||
      [],
    [swapsByNativeId, swapsByDestinationAddress],
  );

  const swapTableRows = useMemo(
    () =>
      swaps
        .slice(0, preview ? 10 : swaps.length)
        .map(({ node }) => (
          <SwapRow
            key={node.id}
            swapRequest={node}
            highlightAddress={highlightAddress}
            setHighlightAddress={setHighlightAddress}
          />
        )),
    [swaps, highlightAddress],
  );

  const paginationControls = preview ? undefined : allSwapsPaginationControls;

  return (
    <div className="flex w-full flex-col space-y-8">
      <div
        className={classNames(
          `flex flex-col gap-y-4 lg:flex-row lg:items-center`,
          preview ? 'gap-x-4' : 'justify-between',
        )}
      >
        <div className={classNames(`text-left text-cf-white`, preview ? 'text-20' : 'text-[32px]')}>
          Latest swaps
        </div>
        {preview ? (
          <Button
            type="secondary-standard"
            size="small"
            iconPos="right"
            onClick={() => router.push('/swaps')}
          >
            View All
          </Button>
        ) : (
          <div className="order-1 ml-auto w-full truncate lg:order-none lg:w-[32rem]">
            <SearchInput
              placeholder="Search by swap ID or destination address"
              value={query}
              onChange={setQuery}
            />
          </div>
        )}
      </div>
      <TableV2
        columns={[
          'Swap Details',
          'Route',
          'Status',
          'Completed in',
          { name: 'Age', alignment: 'right' },
        ]}
        isLoading={swapsByDestinationAddressLoading || swapsByNativeIdLoading}
        rows={swapTableRows}
        paginationControls={paginationControls}
        thClassName="py-4 px-4 text-sm text-cf-light-2"
        emptyRowsContent="No swaps here yet"
      />
    </div>
  );
}
